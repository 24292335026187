import store from "@/store"
import {
	mdiAccountCheckOutline,
	mdiAccountOutline,
	mdiAccountPlusOutline,
	mdiAccountRemoveOutline,
	mdiCogOutline,
	mdiDatabaseOutline,
	mdiDnsOutline,
	mdiPencilOutline,
} from "@mdi/js"
import {ref, watch} from "@vue/composition-api"

export default function useAdminsList() {
	const adminListTable = ref([])

	const tableColumns = [
		{text: "NAME", value: "name"},
		{text: "EMAIL", value: "email"},
		{text: "EMAIL_VERIFIED", value: "email_verified_at"},
		{text: "CREATED AT", value: "created_at", sortable: false},
		{
			text: "ACTIONS",
			value: "actions",
			align: "center",
			sortable: false,
		},
	]

	const searchQuery = ref("")
	const filters = ref([])
	const actionFilter = ref(null)
	const subjectFilter = ref(null)
	const totalAdminListTable = ref(0)
	const loading = ref(false)
	const options = ref({
		sortBy: ["id"],
		sortDesc: [true],
	})
	const adminTotalLocal = ref([])
	const selectedRows = ref([])
	const allRoles = ref([])

	// fetch data
	const fetchAdmins = () => {
		store
			.dispatch("am-admin/fetchAdmins", {
				q: searchQuery.value,
				options: options.value,
				action: actionFilter.value,
				subject: subjectFilter.value,
			})
			.then(response => {
				const {result, adminTotal} = response.data

				adminListTable.value = result.data
				totalAdminListTable.value = result.total
				adminTotalLocal.value = adminTotal

				// remove loading state
				loading.value = false
			})
			.catch(error => {
				console.log(error)
			})
	}

	const fetchAllRoles = () => {
		store
			.dispatch("am-admin/fetchAllRoles", {
				q: searchQuery.value,
				options: options.value,
				listOnly: true
			})
			.then(response => {
				allRoles.value = response.data

				// remove loading state
				loading.value = false
			})
			.catch(error => {
				console.log(error)
			})
	}

	watch([searchQuery, actionFilter, subjectFilter, options], () => {
		loading.value = true
		selectedRows.value = []
		fetchAdmins()
		fetchAllRoles()
	})

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserAdminVariant = admin => {
		if (admin === "subscriber") return "primary"
		if (admin === "author") return "warning"
		if (admin === "maintainer") return "success"
		if (admin === "editor") return "info"
		if (admin === "admin") return "error"

		return "primary"
	}

	const resolveUserAdminIcon = admin => {
		if (admin === "subscriber") return mdiAccountOutline
		if (admin === "author") return mdiCogOutline
		if (admin === "maintainer") return mdiDatabaseOutline
		if (admin === "editor") return mdiPencilOutline
		if (admin === "admin") return mdiDnsOutline

		return mdiAccountOutline
	}

	const resolveUserStatusVariant = status => {
		if (status === "pending") return "warning"
		if (status === "active") return "success"
		if (status === "inactive") return "secondary"

		return "primary"
	}

	const resolveUserTotalIcon = total => {
		if (total === "Total Users") return {icon: mdiAccountOutline, color: "primary"}
		if (total === "Paid Users") return {icon: mdiAccountPlusOutline, color: "error"}
		if (total === "Active Users") return {icon: mdiAccountCheckOutline, color: "success"}
		if (total === "Pending Users") return {icon: mdiAccountRemoveOutline, color: "warning"}

		return {icon: mdiAccountOutline, color: "primary"}
	}

	return {
		adminListTable,
		tableColumns,
		searchQuery,
		filters,
		actionFilter,
		subjectFilter,
		totalAdminListTable,
		loading,
		options,
		adminTotalLocal,
		selectedRows,
		allRoles,
		fetchAdmins,
		fetchAllRoles,
		resolveUserAdminVariant,
		resolveUserAdminIcon,
		resolveUserStatusVariant,
		resolveUserTotalIcon,
	}
}
